/**
 * @generated SignedSource<<8c08f5fe2280687aee2c1bed4a3cec10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VerticalType = "art" | "fashion" | "furniture" | "jewelry" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SbSharedTrackingContainer_itemSearch$data = {
  readonly appliedFilters: ReadonlyArray<{
    readonly name: string | null;
    readonly values: ReadonlyArray<{
      readonly code: string | null;
      readonly displayName: string | null;
      readonly urlLabel: string | null;
    } | null> | null;
  } | null> | null;
  readonly appliedPersonalizationList: ReadonlyArray<string | null> | null;
  readonly browseContentModule: {
    readonly __typename: string;
  } | null;
  readonly categoryTiles: ReadonlyArray<{
    readonly __typename: "SearchBrowseCategoryTiles";
  } | null> | null;
  readonly controlEngagedItemIds: ReadonlyArray<string | null>;
  readonly displayUriRef: string | null;
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly item: {
        readonly serviceId: string | null;
      } | null;
    } | null;
  } | null> | null;
  readonly pageType: string | null;
  readonly searchCorrections: {
    readonly searchType: string | null;
  } | null;
  readonly seller: {
    readonly " $fragmentSpreads": FragmentRefs<"sellerBrandingHelpers_seller">;
  } | null;
  readonly sort: {
    readonly currentOption: {
      readonly urlLabel: string | null;
    } | null;
  } | null;
  readonly topCategoryL1: VerticalType | null;
  readonly totalResults: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedTrackingFunctions_itemSearch">;
  readonly " $fragmentType": "SbSharedTrackingContainer_itemSearch";
};
export type SbSharedTrackingContainer_itemSearch$key = {
  readonly " $data"?: SbSharedTrackingContainer_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedTrackingContainer_itemSearch">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pageType",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlLabel",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "contentModuleId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedTrackingContainer_itemSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "topCategoryL1",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalResults",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayUriRef",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appliedPersonalizationList",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "contentModuleId",
          "variableName": "contentModuleId"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "browseContentModule",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseParsedParam",
      "kind": "LinkedField",
      "name": "appliedFilters",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseParsedParamValue",
          "kind": "LinkedField",
          "name": "values",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "code",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SortType",
      "kind": "LinkedField",
      "name": "sort",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseSortCurrentOption",
          "kind": "LinkedField",
          "name": "currentOption",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseCategoryTiles",
      "kind": "LinkedField",
      "name": "categoryTiles",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchCorrectionsType",
      "kind": "LinkedField",
      "name": "searchCorrections",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "searchType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Seller",
      "kind": "LinkedField",
      "name": "seller",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "sellerBrandingHelpers_seller",
          "selections": [
            {
              "alias": "isRewarded",
              "args": [
                {
                  "kind": "Literal",
                  "name": "listNames",
                  "value": [
                    "SELLER_REWARDS_1",
                    "SELLER_REWARDS_2"
                  ]
                }
              ],
              "kind": "ScalarField",
              "name": "isAnchorListsMember",
              "storageKey": "isAnchorListsMember(listNames:[\"SELLER_REWARDS_1\",\"SELLER_REWARDS_2\"])"
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "controlEngagedItemIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemSearchQueryEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ItemSearchResult",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Item",
              "kind": "LinkedField",
              "name": "item",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "serviceId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "SbSharedTrackingFunctions_itemSearch",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasHiddenSoldItems",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "trackedAbTests",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ItemSearchQueryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ItemSearchResult",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Item",
                  "kind": "LinkedField",
                  "name": "item",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Variable",
                          "name": "isTrade",
                          "variableName": "isTrade"
                        },
                        {
                          "kind": "Literal",
                          "name": "page",
                          "value": "searchAndBrowse"
                        },
                        {
                          "kind": "Variable",
                          "name": "priceBookName",
                          "variableName": "priceBookName"
                        }
                      ],
                      "concreteType": "ItemDisplayPriceType",
                      "kind": "LinkedField",
                      "name": "displayPrice",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "textType",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": [
        {
          "kind": "RootArgument",
          "name": "isTrade"
        },
        {
          "kind": "RootArgument",
          "name": "priceBookName"
        }
      ]
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};
})();

(node as any).hash = "476e25db7f802c2d60306aa739838045";

export default node;
